import React from 'react';
import { theme } from '@fiverr-private/theme';
import { SYSTEM_ICON_SIZES } from '../../constants';
import type { SystemIconType } from '../../types';

const CheckIcon = ({ size = 'sm', color = 'currentColor' }: SystemIconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={SYSTEM_ICON_SIZES[size]}
    height={SYSTEM_ICON_SIZES[size]}
    fill={theme.colors[color]}
    viewBox="0 0 16 16"
  >
    <path d="M14.53 4.53 7 12.06 2.47 7.53l1.06-1.06L7 9.94l6.47-6.47z" />
  </svg>
);
CheckIcon.id = 'CheckIcon';
CheckIcon.displayName = 'CheckIcon';
export default CheckIcon;
