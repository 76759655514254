import React, { useMemo } from 'react';
import { Container } from '@fiverr-private/layout_components';
import { getIsMultiSelect } from '../Dropdown/utils';
import useDropdownContext from '../Dropdown/useDropdownContext';
import { DropdownMenuProps } from './types';
import useMenuContext from './useMenuContext';

const DropdownMenu = ({ children, maxWidth = '400px', maxHeight = '248px', height, width }: DropdownMenuProps) => {
  const { isMultiSelect } = useDropdownContext();
  const { fullWidth } = useMenuContext();
  isMultiSelect.current = useMemo(() => getIsMultiSelect(children), [children]);

  return (
    <Container
      width={fullWidth ? '100%' : width}
      height={height}
      maxWidth={fullWidth ? '100%' : maxWidth}
      maxHeight={maxHeight}
      overflowX="hidden"
      paddingX="2"
    >
      <Container as="ul" display="flex" direction="column" role="list" gap={isMultiSelect?.current ? '2' : undefined}>
        {children}
      </Container>
    </Container>
  );
};

DropdownMenu.id = 'DropdownMenu';
DropdownMenu.displayName = 'DropdownMenu';

export default DropdownMenu;
