import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { CheckIcon } from '@fiverr-private/visuals';
import { Container } from '@fiverr-private/layout_components';
import { Box } from '@fiverr-private/theme';
import Input from '../Input';
import { filterProps } from '../shared/utils';
import { formsTokenColors } from '../shared/formsTokenColors';
import { CheckboxProps } from './types';
import { checkmarkBox, container, input, outline } from './styles.ve.css';
import { getValidationColor } from './style';

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      children,
      disabled = false,
      disableCheckmarkMargin = false,
      isError = false,
      isSuccess = false,
      className,
      disableOutline,
      direction = 'row',
      htmlHidden,
      indeterminate,
      ...rest
    },
    ref
  ) => {
    const { stylingProps, restProps } = filterProps(rest);
    const { color, borderColor } = getValidationColor({ disabled, isError, isSuccess });

    const inputProps = {
      type: 'checkbox',
      disabled,
      ref,
      ...restProps,
    };

    return (
      <Box
        as="label"
        display="inlineFlex"
        direction={direction}
        alignItems="center"
        color={color}
        width="100%"
        boxSizing="borderBox"
        className={classNames(container, className)}
        cursor={disabled ? undefined : 'pointer'}
        {...stylingProps}
      >
        <Input
          role="checkbox"
          width={0}
          height={0}
          opacity="0"
          className={input}
          data-indeterminate={indeterminate}
          {...inputProps}
        />
        <Container
          as="span"
          display="inlineFlex"
          alignItems="center"
          position="relative"
          alignSelf="flexStart"
          borderWidth="sm"
          borderRadius="sm"
          userSelect="none"
          justifyContent="center"
          width="20px"
          height="20px"
          flexShrink="0"
          borderColor={borderColor}
          backgroundColor="background"
          marginRight={disableCheckmarkMargin ? '0' : '2'}
          marginTop={disableCheckmarkMargin ? '0' : 'px'}
          className={classNames(checkmarkBox, {
            [outline]: !disableOutline,
          })}
          hidden={isNil(htmlHidden) ? undefined : Boolean(htmlHidden)}
        >
          {indeterminate ? (
            <Container
              width="10px"
              backgroundColor={disabled ? formsTokenColors.disabledColor : 'white'}
              height="2px"
              borderRadius="circle"
            />
          ) : (
            <CheckIcon color={disabled ? formsTokenColors.disabledColor : 'white'} size="sm" />
          )}
        </Container>
        {children}
      </Box>
    );
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
