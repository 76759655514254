import React from 'react';
import { Button } from '@fiverr-private/button';
import { getProMenusCopiesConfig } from '@fiverr-private/layout_menus';
import { buildLinkHref } from '../../utils/buildLinkHref';

export const PlansButton = () => {
    const { plans } = getProMenusCopiesConfig();
    return (
        <Button
            intent="secondary"
            variant="ghost"
            rel="nofollow"
            href={buildLinkHref({ link: plans.link })}
            onClick={plans.onClick}
        >
            {plans.buttonText}
        </Button>
    );
};
