import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { CheckIcon } from '@fiverr-private/visuals';
import { Checkbox, Input } from '@fiverr-private/inputs';
import { Container } from '@fiverr-private/layout_components';
import { Box, type Types, atoms } from '@fiverr-private/theme';
import type { ItemValue } from '../../Dropdown/useDropdownState/types';
import useDropdownContext from '../../Dropdown/useDropdownContext';
import useMenuContext from '../useMenuContext';
import useMenuGroupContext from '../useMenuGroupContext';
import type { MenuItemProps } from './types';
import { DEFAULT_ITEM_NAME } from './constants';

const MenuItem = ({ children, value, selectable, disabled = false, href, target, onClick }: MenuItemProps) => {
  const itemRef = useRef<HTMLLIElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const { onChange, values, focusedItem, setFocusedItem, items, anchorRef, selectedIndicatorPosition } =
    useDropdownContext();
  const { addMenuItem, removeMenuItem, closeMenu, isDark } = useMenuContext();
  const { isMulti, name = DEFAULT_ITEM_NAME, hideSelectedIndicator } = useMenuGroupContext();
  const isChecked = useMemo(
    () =>
      Boolean(
        isMulti ? (values[name] as ItemValue[])?.includes(value?.toString()) : values[name] === value?.toString()
      ),
    [isMulti, values, name, value]
  );

  const shouldUseAnchorElement = href && !disabled;
  const MenuItemWrapper = shouldUseAnchorElement ? Box : React.Fragment;
  const menuItemWrapperProps: Types.BoxType = shouldUseAnchorElement
    ? {
        as: 'a',
        href,
        target,
        decoration: { default: 'none', hover: 'none' },
        color: { default: disabled ? 'grey_800' : 'grey_1100', hover: disabled ? 'grey_800' : 'grey_1100' },
      }
    : {};
  const handleOnMouseEnter = useCallback(() => setFocusedItem(itemRef), [setFocusedItem]);
  const handleOnChange = useCallback(
    ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => onChange(currentTarget),
    [onChange]
  );

  const inputProps = {
    name,
    value,
    checked: isChecked,
    ref: inputRef,
    onChange: handleOnChange,
    disabled,
  };
  const toggleValue = useCallback(() => {
    if (selectable && !disabled) {
      const { name, value, type, checked } = inputRef.current as HTMLInputElement;
      onChange({ name, value, type, checked: !checked });
    }
  }, [onChange, selectable, disabled]);

  const handleOnClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (disabled) {
        return;
      }

      if (MenuItemWrapper === React.Fragment) {
        event.preventDefault();
      }

      closeMenu();
      onClick?.(event);
      toggleValue();
    },
    [MenuItemWrapper, closeMenu, disabled, onClick, toggleValue]
  );

  useEffect(() => {
    if (selectable) {
      addMenuItem({
        ref: itemRef,
        type: isMulti ? 'checkbox' : 'radio',
        value: value?.toString(),
        name,
        disabled,
        toggleValue,
      });
    } else {
      addMenuItem({
        ref: itemRef,
        name,
        disabled,
      });
    }

    return () => {
      removeMenuItem(itemRef);
    };
  }, [isMulti, selectable, disabled, value, name, addMenuItem, removeMenuItem, toggleValue]);

  useEffect(() => {
    if (itemRef === focusedItem) {
      setIsFocused(true);

      const previousElementSibling = itemRef?.current?.previousElementSibling;

      if (focusedItem === items[0].ref && previousElementSibling) {
        previousElementSibling?.scrollIntoView({ block: 'nearest', inline: 'start' });
        return;
      }
      itemRef.current?.scrollIntoView({ block: 'nearest' });
    } else {
      setIsFocused(false);
    }
  }, [focusedItem, items]);

  const getOptionStyles = (): Types.BoxType => ({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    paddingY: '2',
    paddingX: '3',
    cursor: 'pointer',
    pointerEvents: disabled ? 'none' : undefined,
  });

  const focusedBackgroundColor = isDark ? 'white_20' : 'grey_200';
  const color = isDark ? 'white' : 'grey_1100';
  const borderRadius = isDark ? 'xl' : 'lg';

  return (
    <MenuItemWrapper {...menuItemWrapperProps}>
      <Container
        as="li"
        minWidth="200px"
        borderRadius={borderRadius}
        color={disabled ? 'grey_800' : color}
        userSelect="none"
        backgroundColor={isFocused && !disabled ? focusedBackgroundColor : undefined}
        onMouseEnter={handleOnMouseEnter}
        tabIndex={-1}
        ref={itemRef}
        onClick={handleOnClick}
        onFocus={() => {
          anchorRef.current?.focus();
        }}
        role="listitem"
        aria-disabled={disabled}
      >
        {selectable && !isMulti && (
          <Container
            {...getOptionStyles()}
            as="label"
            direction={selectedIndicatorPosition === 'right' ? 'rowReverse' : undefined}
            width="100%"
          >
            <Input visibility="hidden" position="absolute" type="radio" role="radio" {...inputProps} />
            <Container
              display="flex"
              alignItems="center"
              height="16px"
              width="16px"
              marginRight="3"
              htmlHidden={hideSelectedIndicator}
              role="img"
            >
              {isChecked && <CheckIcon color="grey_1100" />}
            </Container>
            <Container flexGrow={1}>{children}</Container>
          </Container>
        )}
        {selectable && isMulti && (
          <Checkbox
            htmlHidden={hideSelectedIndicator}
            className={atoms(getOptionStyles())}
            direction={selectedIndicatorPosition === 'right' ? 'rowReverse' : undefined}
            width="100%"
            {...inputProps}
          >
            <Container flexGrow={1}>{children}</Container>
          </Checkbox>
        )}
        {!selectable && (
          <Container {...getOptionStyles()} as="label">
            {children}
          </Container>
        )}
      </Container>
    </MenuItemWrapper>
  );
};

MenuItem.displayName = 'MenuItem';

export default MenuItem;
