import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import { rollouts } from '../../PropTypes';
import withContext from '../../lib/withContext';
import Nav from '../../lib/Nav';
import Layout from '../../components/Layout';
import Row from '../../components/Row';
import { BusinessSidebarTrigger } from '../../components/BusinessSidebarTrigger';
import BusinessNavSearch from '../../components/BusinessNavSearch';
import { FiverrBusinessProLinkLogo } from '../../components/FiverrBusinessProLinkLogo';
import { BusinessLoginButton } from '../../components/BusinessLoginButton';
import { BusinessProJoinButton } from '../../components/BusinessProJoinButton';
import { BecomeFreelancerButton } from '../../components/BecomeFreelancerButton';
import { BusinessWhyFiverrProNavigation } from '../../components/BusinessWhyFiverrProNavigation';
import LeanLocaleSettingsLink from '../../components/LocaleSettingsLinks/LeanLocaleSettingsLink';
import { PlansButton } from '../../components/PlansButton';
import { ProProductsNavigation } from '../../components/ProProductsNavigation';
import { ProResourcesNavigation } from '../../components/ProResourcesNavigation';
import { BusinessContactSalesButton } from '../../components/BusinessContactSalesButton';
import { isAllowedFibContact } from '../../utils/isAllowedFibContact';

import styles from './BusinessProVisitorLoggedOut.module.scss';

import './BusinessProVisitorLoggedOut.styles.scss';

const BusinessProVisitorLoggedOut = ({ primarySearchVisible = true }, { rollouts }) => {
    const { locale } = getContext();
    const shouldShowWhyPro = locale === 'en-US' || !primarySearchVisible;
    const inDolphinsProLohpNewHeader = rollouts.inDolphinsProLohpNewHeader;

    return (
        <Layout className="fiverr-for-business-pro">
            <Row className={classnames(styles.row, 'flex flex-items-center')}>
                <BusinessSidebarTrigger displayFromLG />
                <FiverrBusinessProLinkLogo />
                <BusinessNavSearch
                    className="m-l-24"
                    mode={BusinessNavSearch.MODES.DESKTOP}
                    theme="dark"
                    isVisible={primarySearchVisible}
                />
                <Nav className={styles.nav} displayFromSM>
                    {inDolphinsProLohpNewHeader ? (
                        <>
                            <Nav.Item className={classnames(styles.navItemButton, 'p-r-0 m-r-8')} displayFromLG>
                                <ProProductsNavigation />
                            </Nav.Item>
                            <Nav.Item className={classnames(styles.navItemButton, 'p-r-0 m-r-8')} displayFromLG>
                                <PlansButton />
                            </Nav.Item>
                            <Nav.Item className={classnames(styles.navItemButton, 'p-r-0 m-r-8')} displayFromLG>
                                <ProResourcesNavigation />
                            </Nav.Item>
                            <Nav.Item className={classnames(styles.navItemButton, 'p-r-0 m-r-8')} displayFromLG>
                                <BecomeFreelancerButton />
                            </Nav.Item>
                        </>
                    ) : (
                        <>
                            <Nav.Item className={classnames(styles.navItemButton, 'p-r-0 m-r-8')} displayFromLG>
                                <BecomeFreelancerButton />
                            </Nav.Item>
                            {shouldShowWhyPro && (
                                <Nav.Item className={classnames(styles.navItemButton, 'p-r-0 m-r-8')} displayFromLG>
                                    <BusinessWhyFiverrProNavigation />
                                </Nav.Item>
                            )}
                            {isAllowedFibContact() && (
                                <Nav.Item className={classnames(styles.navItemButton, 'p-r-0 m-r-8')} displayFromLG>
                                    <BusinessContactSalesButton />
                                </Nav.Item>
                            )}
                        </>
                    )}
                    <Nav.Item className={classnames(styles.navItemButton, 'p-r-0  m-r-8')} displayFromLG>
                        <LeanLocaleSettingsLink hideLabel />
                    </Nav.Item>
                    <Nav.Item className={classnames(styles.navItemButton, styles.signIn)} displayFromSM>
                        <BusinessLoginButton className={styles.actionBtn} />
                    </Nav.Item>
                    <Nav.Item className="p-r-0" displayFromSM>
                        <BusinessProJoinButton className={styles.actionBtn} withShortTitle />
                    </Nav.Item>
                </Nav>
            </Row>
            <BusinessNavSearch mode={BusinessNavSearch.MODES.MOBILE} theme="dark" isVisible={primarySearchVisible} />
        </Layout>
    );
};

BusinessProVisitorLoggedOut.propTypes = {
    primarySearchVisible: PropTypes.bool,
};

BusinessProVisitorLoggedOut.contextTypes = {
    rollouts,
};

/**
 * @param {HeaderProps} props
 * @returns {React.ReactElement} Header
 */
export default withContext(BusinessProVisitorLoggedOut);
