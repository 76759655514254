import React from 'react';
import { Dropdown, DropdownAnchor, DropdownContent, DropdownMenu, MenuItem } from '@fiverr-private/dropdown';
import { Text, Typography } from '@fiverr-private/typography';
import { getProMenusCopiesConfig } from '@fiverr-private/layout_menus';
import { Divider, Container } from '@fiverr-private/layout_components';
import { DropdownTriggerButton } from '../DropdownTriggerButton';
import { buildLinkHref } from '../../utils/buildLinkHref';

const SectionHeader: React.FC<{ title: React.ReactNode }> = ({ title }) => (
    <Typography size="b_md" color="grey_1100" fontWeight="bold" paddingY="2" paddingX="3">
        {title}
    </Typography>
);

export const ProProductsNavigation: React.FC = () => {
    const { buttonText, benefits, services, onClick } = getProMenusCopiesConfig().pro_products;

    return (
        <Dropdown>
            <DropdownAnchor embed>
                <DropdownTriggerButton title={buttonText} onClick={onClick} />
            </DropdownAnchor>
            <DropdownContent fullWidth>
                <Container display="flex" minWidth="500px" paddingX="2" paddingY="2.5">
                    <DropdownMenu maxHeight="auto" width="max-content">
                        <SectionHeader title={benefits.title} />
                        {benefits.items.map((item) => (
                            <MenuItem key={item.text} href={buildLinkHref({ link: item.link })} onClick={item.onClick}>
                                <Text size="b_sm">{item.text}</Text>
                            </MenuItem>
                        ))}
                    </DropdownMenu>
                    <Divider direction="vertical" height="auto" />
                    <DropdownMenu maxHeight="auto" width="max-content">
                        <SectionHeader title={services.title} />
                        {services.items.map((item) => (
                            <MenuItem key={item.text} href={buildLinkHref({ link: item.link })} onClick={item.onClick}>
                                <Text size="b_sm">{item.text}</Text>
                            </MenuItem>
                        ))}
                    </DropdownMenu>
                </Container>
            </DropdownContent>
        </Dropdown>
    );
};
