import { createContext, useContext } from 'react';
import { MenuGroupProps } from '../MenuGroup/types';

export const MenuGroupContext = createContext<Pick<MenuGroupProps, 'isMulti' | 'name' | 'hideSelectedIndicator'>>({
    isMulti: false,
});

const useMenuGroupContext = () => useContext(MenuGroupContext);

export default useMenuGroupContext;
