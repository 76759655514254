import { Types } from '@fiverr-private/theme';

export const filterProps = (props: Types.Box): { restProps: Types.Box; stylingProps: Types.GlobalStylingProps } => {
    const {
        position,
        margin,
        marginX,
        marginY,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        width,
        height,
        maxWidth,
        maxHeight,
        top,
        right,
        bottom,
        left,
        order,
        overflow,
        overflowX,
        overflowY,
        minHeight,
        minWidth,
        visibility,
        hidden,
        flex,
        flexGrow,
        flexShrink,
        flexBasis,
        alignSelf,
        justifySelf,
        ...restProps
    } = props;

    return {
        restProps,
        stylingProps: {
            position,
            margin,
            marginX,
            marginY,
            marginBottom,
            marginLeft,
            marginRight,
            marginTop,
            width,
            height,
            maxWidth,
            maxHeight,
            top,
            right,
            bottom,
            left,
            order,
            overflow,
            overflowX,
            overflowY,
            minHeight,
            minWidth,
            visibility,
            hidden,
            flex,
            flexGrow,
            flexShrink,
            flexBasis,
            alignSelf,
            justifySelf,
        },
    };
};
