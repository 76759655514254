import React, { forwardRef } from 'react';
import classnames from 'classnames';
import { Box, utilityClasses } from '@fiverr-private/theme';
import { INPUT_ID } from './constants';
import { InputProps } from './types';
import { inputSearchSelector, hideNumberSpinButtonClass, successClass, errorClass, input } from './styles.ve.css';

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type = 'text',
      disabled = false,
      isSuccess = false,
      isError = false,
      hideNumberSpinButton = false,
      width = '100%',
      ...rest
    },
    ref
  ) => {
    const inputClassName = classnames(
      {
        [hideNumberSpinButtonClass]: type === 'number' && hideNumberSpinButton,
        [successClass]: isSuccess,
        [errorClass]: isError,
      },
      input,
      inputSearchSelector,
      utilityClasses.placeholder,
      className
    );

    const inputProps = {
      disabled,
      type,
      ref,
      ...rest,
    };

    return (
      <Box
        as="input"
        display="inlineBlock"
        position="relative"
        width={width}
        boxSizing="borderBox"
        className={inputClassName}
        {...inputProps}
      />
    );
  }
);

Input.displayName = INPUT_ID;

Object.assign(Input, {
  id: INPUT_ID,
});

export default Input;
