import React from 'react';
import { func } from 'prop-types';
import { Dropdown } from '@fiverr-private/orca';
import { rollouts } from '../../PropTypes';
import { WhyFiverrProButton } from './WhyFiverrProButton';
import { WhyFiverrProMenu } from './WhyFiverrProMenu';

import styles from './styles.module.scss';

export const BusinessWhyFiverrProNavigation = ({ onOpen }) => (
    <Dropdown
        containerClassName={styles.menuContainer}
        onOpen={onOpen}
        position={Dropdown.POSITIONS.RIGHT}
        menu={WhyFiverrProMenu}
    >
        <WhyFiverrProButton />
    </Dropdown>
);

BusinessWhyFiverrProNavigation.propTypes = {
    onOpen: func,
};

BusinessWhyFiverrProNavigation.contextTypes = {
    rollouts,
};
