import React from 'react';
import { getProMenusCopiesConfig } from '@fiverr-private/layout_menus';
import { Text } from '@fiverr-private/typography';
import { Container } from '@fiverr-private/layout_components';
import { Dropdown, DropdownAnchor, DropdownContent, DropdownMenu, MenuItem } from '@fiverr-private/dropdown';
import { DropdownTriggerButton } from '../DropdownTriggerButton';
import { buildLinkHref } from '../../utils/buildLinkHref';

interface ProResourcesNavigationProps {
    resources: { text: string; link: string }[];
}

export const ProResourcesNavigation: React.FC<ProResourcesNavigationProps> = () => {
    const { buttonText, items, onClick } = getProMenusCopiesConfig().pro_resources;
    return (
        <Dropdown>
            <DropdownAnchor embed>
                <DropdownTriggerButton title={buttonText} onClick={onClick} />
            </DropdownAnchor>
            <DropdownContent>
                <Container padding="2">
                    <DropdownMenu>
                        {items.map((item) => (
                            <MenuItem key={item.text} href={buildLinkHref({ link: item.link })} onClick={item.onClick}>
                                <Text size="b_sm">{item.text}</Text>
                            </MenuItem>
                        ))}
                    </DropdownMenu>
                </Container>
            </DropdownContent>
        </Dropdown>
    );
};
