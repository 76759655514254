import React from 'react';
import classnames from 'classnames';
import { string } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { Button } from '@fiverr-private/button';
import { TOP_BAR_SOURCE } from '../../constants';
import { isAllowedFibContact } from '../../utils/isAllowedFibContact';

import styles from './index.module.scss';

const href = `https://fiverr.com/fbs-contact-us?source=${TOP_BAR_SOURCE}`;

export const BusinessContactSalesButton = ({ className, intent = 'secondary', variant = 'ghost', ...props } = {}) => {
    if (!isAllowedFibContact()) {
        return null;
    }

    return (
        <Button
            className={classnames(styles.button, className)}
            intent={intent}
            variant={variant}
            rel="nofollow"
            href={href}
            {...props}
        >
            <I18n k="header.contact_sales" />
        </Button>
    );
};

BusinessContactSalesButton.propTypes = {
    className: string,
    intent: string,
    variant: string,
    source: string,
};
