import type { Types } from '@fiverr-private/theme';

const tokens: { [K: string]: Types.Color } = {
    color: 'grey_1200',
    disabledColor: 'grey_800',
    disabledFieldBackgroundColor: 'grey_200',
    disabledToggleBackgroundColor: 'grey_400',
    borderColor: 'grey_500',
    interactiveColor: 'grey_1200',
};

export const formsTokenColors: { [K in keyof typeof tokens]: Types.Color } = {
    ...tokens,
} as const;
