import { noop } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { stats } from '@fiverr-private/obs';
import type { CtaData } from '@fiverr-private/business_success_sign_modal';
import { deprecatedLogger } from '../../utils';
import { MigrationBiEnrichment, MigrationFlowContext } from './types';

export interface OpenFibMigrationEventDetails {
    withSkipAction: boolean;
    biEnrichment: MigrationBiEnrichment;
    flowContext: MigrationFlowContext;
    redirectCtaData?: CtaData;
    onSuccess: () => void;
    onError: () => void;
    onLoadError: (err?: unknown) => void;
    onSkip: () => void;
    source: string;
}

type OptionalFields = 'withSkipAction' | 'redirectCtaData' | 'onSuccess' | 'onError' | 'onLoadError' | 'onSkip';
/** @deprecated */
export interface OpenFibMigrationEventPayload
    extends Omit<OpenFibMigrationEventDetails, OptionalFields>,
        Partial<Pick<OpenFibMigrationEventDetails, OptionalFields>> {}

export type OpenFibMigrationEvent = CustomEvent<OpenFibMigrationEventPayload & { source: string; isOpen?: boolean }>;

export const APP_NAME = 'migration';
export const EVENT_NAME = 'openFibMigration';
const WINDOW_INDICATION = 'isFibMigrationLoaded';
const USER_JUST_MIGRATED_INDICATION = 'userJustMigratedToFib';

export const isMigrationMounted = () => !!window[WINDOW_INDICATION];

export const markFibMigrationMounted = (isLoaded) => {
    window[WINDOW_INDICATION] = isLoaded;
};

export const isUserJustMigratedToFib = () => !!window[USER_JUST_MIGRATED_INDICATION];

export const markUserJustMigratedToFib = () => {
    window[USER_JUST_MIGRATED_INDICATION] = true;
};

export const setupEventHandler = (handler) => addEventListener(EVENT_NAME, handler);

export const removeEventHandler = (handler) => removeEventListener(EVENT_NAME, handler);

export const shouldDisplayMigrationPopup = () => {
    const { userId, organizationId, experience } = getContext();
    const isNoneBusinessUser = userId && !organizationId;

    if (isUserJustMigratedToFib()) {
        return false;
    }

    if (isNoneBusinessUser && experience.isBusiness) {
        return true;
    }

    return false;
};

/** @deprecated Use "openFipMigrationModal" */
export const openFibMigration = ({
    withSkipAction,
    biEnrichment,
    redirectCtaData = undefined,
    onSuccess = noop,
    onError = noop,
    onLoadError = noop,
    onSkip = noop,
    flowContext,
}: OpenFibMigrationEventPayload) => {
    const { pageElementName, trigger } = biEnrichment;
    const source = `${pageElementName}.${trigger}`;
    const monitoringPrefix = `${APP_NAME}.trigger.${source}`;
    stats.count(monitoringPrefix, 'call');

    deprecatedLogger({
        message: 'Called openFibMigration. Use new "openFipMigrationModal" trigger from pro_migration package',
        componentName: 'openFibMigration',
        source,
    });

    if (isMigrationMounted()) {
        const customOnLoadError = () => {
            stats.count(monitoringPrefix, 'on_load_error_called');
            onLoadError();
        };

        const event: OpenFibMigrationEvent = new CustomEvent(EVENT_NAME, {
            detail: {
                withSkipAction,
                flowContext,
                redirectCtaData,
                biEnrichment,
                source,
                onSuccess,
                onError,
                onSkip,
                onLoadError: customOnLoadError,
            } satisfies OpenFibMigrationEvent['detail'],
        });
        window.dispatchEvent(event);
        stats.count(monitoringPrefix, 'dispatched');
        return true;
    }

    stats.count(monitoringPrefix, 'not_mounted');
    return false;
};

export enum FLOW_CONTEXT_TYPES {
    requestQuote = 'request_a_quote',
    purchase = 'purchase',
    contact = 'contact',
    convert = 'convert',
    save_expert = 'save_expert',
    join_button = 'join_button',
    no_result_page_brief = 'no_result_page_brief',
    no_result_page_chat = 'no_result_page_chat',
}
